import { Component } from '@angular/core';

@Component({
  selector: 'qr-root',
  template: `
    <lib-group-loading></lib-group-loading>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {}

